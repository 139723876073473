/**
 * Foursource page
 * https://foursource.com/explore-network
 */

import * as React from "react";
import { withTrans } from "../i18n/withTrans";

// components
import Layout from "../components/layout";

class exploreNetworkPage extends React.Component {
  constructor(props) {
    super(props);
    this.t = props.t;
  }

  render() {
    return (
      <>
        <Layout pageName="explore-network" whiteMenu={true}></Layout>
      </>
    );
  }
}

export default withTrans(exploreNetworkPage);
